<template>
    <div>
       	<div class="crumbs">
			   <el-breadcrumb separator="/">
				   <el-breadcrumb-item>
					   <i class="el-icon-lx-cascades"></i> 下级用户收益详情
				   </el-breadcrumb-item>
			   </el-breadcrumb>
		</div>
		<div class="container">
		    <div class="handle-box">
		      <!--  <el-button
		            type="primary"
		            icon="el-icon-delete"
		            class="handle-del mr10"
		            @click="delAllSelection"
		        >批量删除</el-button> -->
		    <!--    <el-select v-model="query.address" placeholder="地址" class="handle-select mr10">
		            <el-option key="1" label="广东省" value="广东省"></el-option>
		            <el-option key="2" label="湖南省" value="湖南省"></el-option>
		        </el-select> -->
		       <!-- <el-input v-model="query.name" placeholder="手机号" class="handle-input mr10"></el-input>
		        <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
				<el-button type="primary"  @click="handleSearch">导出EXCLE</el-button> -->
		    </div>
		    <el-table
		        :data="tableData"
		        border
		        class="table"
		        ref="multipleTable"
		        header-cell-class-name="table-header"
		        @selection-change="handleSelectionChange"
		    >
<!-- 		        <el-table-column type="selection" width="55" align="center"></el-table-column>
 -->		        <el-table-column prop="id" label="ID"></el-table-column>
				<!-- <el-table-column prop="id" label="ID" width="55" align="center"></el-table-column> -->
		     <el-table-column prop="type_name" label="产品类型"></el-table-column>
			 <el-table-column prop="money" label="佣金收益"></el-table-column>
				
		       <el-table-column label="状态" align="center">
		            <template #default="scope">
						<el-tag v-if="scope.row.state ===0" type="danger" >预估收益</el-tag>
						<el-tag v-if="scope.row.state ===1" type="success" >已结算收益</el-tag>
		                <!-- <el-tag
		                    :type="
		                        scope.row.state === 1 
		                            ? 'success'
		                            : scope.row.state === 0
		                            ? 'danger'
		                            : ''
		                    "
		                >{{ scope.row.state }}</el-tag> -->
		            </template>
		        </el-table-column>
				<el-table-column prop="datetime" label="收益时间"></el-table-column>
				

		    </el-table>
		    <div class="pagination">
		        <el-pagination
		            background
		            layout="total, prev, pager, next"
		            :current-page="query.p"
		            :page-size="query.num_of_page"
		            :total="pageTotal"
		            @current-change="handlePageChange"
		        ></el-pagination>
		    </div>
		</div>
		
	</div>
</template>

<script>
import {GetSingleProfit} from "../api/index";
	
export default {
    name: "singlesub",
	data() {
	    return {
	        query: {
	            num_of_page:40,
				p:1,
				promote_id:1004,
	           
	        },
	        tableData: [],
	        multipleSelection: [],
	        delList: [],
	        editVisible: false,
	        pageTotal: 0,
	        form: {},
	        idx: -1,
	        id: -1,
			
			
	    };
	},
	mounted() {
	        this.getData();
	    },
	    methods: {
			
	        getData() {
				console.log("111111")
				this.query.promote_id = this.$route.query.promote_id
	            GetSingleProfit(this.query).then(res => {
	                console.log(res.data);
	                this.tableData = res.data.results;
	                this.pageTotal = res.data.total || 0;
	            });
	        },
	        // 触发搜索按钮
	        handleSearch() {
	            this.$set(this.query, "pageIndex", 1);
	            this.getData();
	        },
	        // 删除操作
	        handleDelete(index) {
	            // 二次确认删除
	            this.$confirm("确定要删除吗？", "提示", {
	                type: "warning"
	            })
	                .then(() => {
	                    this.$message.success("删除成功");
	                    this.tableData.splice(index, 1);
	                })
	                .catch(() => {});
	        },
	        // 多选操作
	        handleSelectionChange(val) {
	            this.multipleSelection = val;
	        },
	        delAllSelection() {
	            const length = this.multipleSelection.length;
	            let str = "";
	            this.delList = this.delList.concat(this.multipleSelection);
	            for (let i = 0; i < length; i++) {
	                str += this.multipleSelection[i].name + " ";
	            }
	            this.$message.error(`删除了${str}`);
	            this.multipleSelection = [];
	        },
	        // 编辑操作
	        handleEdit(row) {
	            // this.idx = index;
	            // this.form = row;
	            // this.editVisible = true;
				 this.$router.push({
				'path':'/singlesub',
				'name':'singlesub',
				query:{promote_id:row.promote_id}
				  
				});

	        },
	        // 保存编辑
	        saveEdit() {
	            this.editVisible = false;
	            this.$set(this.tableData, this.idx, this.form);
	        },
	        // 分页导航
	        handlePageChange(val) {
	            this.query.p=val
	            this.getData();
	        }
	    }
	
};
</script>

<style scoped>
.el-row {
    margin-bottom: 20px;
}

.grid-content {
    display: flex;
    align-items: center;
    height: 80px;
}

.grid-cont-right {
    flex: 1;
    text-align: center;
    font-size: 10px;
    color: #999;
}

.grid-num {
    font-size: 20px;
    font-weight: bold;
}

.grid-con-icon {
    font-size: 24px;
    width: 60px;
    height: 80px;
    text-align: center;
    line-height: 80px;
    color: #fff;
}

.grid-con-1 .grid-con-icon {
    background: rgb(45, 140, 240);
}

.grid-con-1 .grid-num {
    color: rgb(45, 140, 240);
}

.grid-con-2 .grid-con-icon {
    background: rgb(100, 213, 114);
}

.grid-con-2 .grid-num {
    color: rgb(45, 140, 240);
}

.grid-con-3 .grid-con-icon {
    background: rgb(242, 94, 67);
}

.grid-con-3 .grid-num {
    color: rgb(242, 94, 67);
}
.handle-box {
    margin-bottom: 20px;
}

.handle-select {
    width: 120px;
}

.handle-input {
    width: 200px;
    display: inline-block;
}
.table {
    width: 100%;
    font-size: 14px;
}
.red {
    color: #ff0000;
}
.mr10 {
    margin-right: 10px;
}
.table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}
</style>